
import Header from "@/components/home-page/Header.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import CheckBox from "@/components/CheckBox.vue";
import CustomButton from "@/components/CustomButton.vue";
import AvatarImage from "@/components/AvatarImage.vue";
import CustomDropDown from "@/components/CustomDropDown.vue";
import { permissions } from "@/constants/rolePermissions";
import User from "@/interfaces/User";
import { getBot, inviteBot } from "@/services/botService";
import Server from "@/interfaces/Server";
import { PopoutsModule } from "@/store/modules/popouts";
import { bitwiseContains } from "@/utils/bitwise";

import { defineComponent } from "vue";
export default defineComponent({
  name: "InviteBot",
  components: {
    Header,
    CheckBox,
    LoadingScreen,
    AvatarImage,
    CustomButton,
    CustomDropDown,
  },
  data() {
    return {
      bot: null as User | null,
      servers: null as Partial<Server>[] | null,
      loggedIn: false,
      selectedServerID: null as string | null,
      requestSent: false,
    };
  },
  computed: {
    mappedServers(): any {
      return (
        this.servers?.map((server) => {
          return {
            name: server.name,
            server_id: server.server_id,
            avatar: {
              seedID: server.server_id,
              imageID: server.avatar,
            },
          };
        }) || []
      );
    },
    botID(): any {
      return this.$route.params.botid;
    },
    permNumber(): any {
      const str: any = this.$route.query.perms;
      return parseInt(str || "0") || 0;
    },
    perms(): any {
      return Object.values(permissions).filter((p) =>
        bitwiseContains(this.permNumber, p.value)
      );
    },
  },
  mounted() {
    getBot(this.botID, false, true).then((data: any) => {
      if (data.servers) {
        this.servers = data.servers;
        this.bot = data.bot;
        this.loggedIn = true;
        return;
      }
      this.bot = data;
    });
  },
  methods: {
    selectedServer(serverID: any) {
      this.selectedServerID = serverID;
    },
    inviteBot() {
      if (!this.bot?.id) return;
      if (!this.selectedServerID) return;
      if (this.requestSent) return;
      this.requestSent = true;
      inviteBot(this.bot.id, this.selectedServerID, this.permNumber)
        .then(() => {
          location.href = "/app";
        })
        .catch(async (err) => {
          PopoutsModule.ShowPopout({
            id: "error",
            component: "generic-popout",
            data: {
              title: "Error Creating Bot",
              description: !err.response
                ? this.$t("could-not-connect-to-server")
                : (await err.response.json()).message,
            },
          });
        })
        .finally(() => (this.requestSent = false));
    },
    loginButton() {
      this.$router.push("/login?redirect=" + encodeURIComponent(location.href));
    },
  },
});
